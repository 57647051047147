const stickyHeaders = {
  mounted: function () {
    ve.veb.stickyHeaderFix();
  },
  updated: function () {
    ve.veb.stickyHeaderFix();
  },
};

export { stickyHeaders };
