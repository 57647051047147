/* global ve, mqtt */
import {
  addToLog,
  initVeEnvLoc,
  sleep,
  hasProp,
  getVeEnvLoc,
  getVeEnvLocAwsRegion,
  getVeEnvLocIdentityPoolId,
  getVeEnvLocUserPoolId,
  getVeEnvLocCognitoAppClientId,
  getVeEnvLocCognitoAppWebDomain,
  getCognitoRedirectUri,
  getVeEnvLocIotEndpoint,
  parseJwt
} from './veb.js'
import cfg from './vecfg.js'
const vecfg = cfg.cfg
addToLog('vecfg: ' + JSON.stringify(vecfg), false) // todo: remove after testing

const getAmplifyConfig = (veEnvLoc, debug) => {
  const amplifyConfig = {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: getVeEnvLocIdentityPoolId(vecfg, veEnvLoc, debug),
      // REQUIRED - Amazon Cognito Region
      region: getVeEnvLocAwsRegion(vecfg, veEnvLoc, debug),
      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: getVeEnvLocAwsRegion(vecfg, veEnvLoc, debug),
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: getVeEnvLocUserPoolId(vecfg, veEnvLoc, debug),
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: getVeEnvLocCognitoAppClientId(vecfg, veEnvLoc, debug),
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true, // todo: review
      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      /*
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: '.village.energy', // getVeEnvLocCognitoAppWebDomain(vecfg, veEnvLoc, debug), // '.yourdomain.com',
        // OPTIONAL - Cookie path
        path: '/',
        // OPTIONAL - Cookie expiration in days
        expires: 365,
        // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: 'strict', // "strict" | "lax",
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true
      },
      */
      // OPTIONAL - customized storage object
      // storage: MyStorage,
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: 'USER_PASSWORD_AUTH',
      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      // clientMetadata: { myCustomKey: 'myCustomValue' },
      // OPTIONAL - Hosted UI configuration
      oauth: {
        domain: getVeEnvLocCognitoAppWebDomain(vecfg, veEnvLoc, debug),
        scope: ['email', 'openid'], // 'profile', 'phone', 'aws.cognito.signin.user.admin'], // todo: review
        redirectSignIn: getCognitoRedirectUri(debug),
        redirectSignOut: getCognitoRedirectUri(debug),
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      } // ,
      // federationTarget: 'COGNITO_USER_POOLS'
    }
  }
  addToLog('getAmplifyConfig:amplifyConfig: ' + JSON.stringify(amplifyConfig), debug)
  return amplifyConfig
}

const getCurrentAmplifyConfig = (Auth, debug = false) => { // get the current config object
  try {
    const currentConfig = Auth.configure()
    addToLog('getCurrentAmplifyConfig:currentConfig: ' + JSON.stringify(currentConfig), debug)
    return currentConfig
  } catch (error) {
    addToLog('getCurrentAmplifyConfig:error: ' + JSON.stringify(error), true)
  }
}

const initAmplifyConfig = (thisEnvLoc, debug = false) => {
  // debug = true
  addToLog('initAmplifyConfig("' + thisEnvLoc + '")...', debug)
  // init ve environment
  if (!thisEnvLoc) thisEnvLoc = 've-cd-dev-au-1' // default
  initVeEnvLoc(thisEnvLoc, debug) // init environment
  const amplifyConfig = initAmplifyConfig2(ve.Amplify, ve.Auth, thisEnvLoc, debug)
  addToLog('initAmplifyConfig:ve.Amplify.configure(): ' + JSON.stringify(ve.Amplify.configure()), debug)
  addToLog('initAmplifyConfig:ve.Auth.configure(): ' + JSON.stringify(ve.Auth.configure()), debug)
  return amplifyConfig
}

const initAmplifyConfig2 = (Amplify, Auth, veEnvLoc, debug = false) => {
  const amplifyConfig = getAmplifyConfig(veEnvLoc, debug)
  Amplify.configure(amplifyConfig)
  Auth.configure(amplifyConfig.Auth.oauth)
  return getCurrentAmplifyConfig(Auth, debug)
}

const doAmplifySignIn = (Amplify, Auth, veEnvLoc, debug = false) => {
  // debug = true
  addToLog('doAmplifySignIn("' + veEnvLoc + '")...', debug)
  const amplifyConfig = initAmplifyConfig(veEnvLoc, debug)
  addToLog('doAmplifySignIn:amplifyConfig: ' + JSON.stringify(amplifyConfig), debug)
  const { domain, redirectSignIn, responseType } = amplifyConfig.oauth
  const clientId = amplifyConfig.userPoolWebClientId
  // The url of the Cognito Hosted UI
  const url = 'https://' + domain + '/login?redirect_uri=' + redirectSignIn + '&response_type=' + responseType + '&client_id=' + clientId
  addToLog('doAmplifySignIn:url: ' + url, debug)
  // Launch hosted UI
  window.location.assign(url)
}

const doAmplifyFederatedSignIn = (Amplify, Auth, veEnvLoc, debug = false) => {
  addToLog('doAmplifyFederatedSignIn("' + veEnvLoc + '")...', debug)
  const amplifyConfig = initAmplifyConfig(veEnvLoc, debug)
  addToLog('doAmplifyFederatedSignIn:amplifyConfig: ' + JSON.stringify(amplifyConfig), debug)
  Auth.federatedSignIn({ customProvider: 'VillageEnergy-O365' }) // todo: add provider to vecfg ??
}

let amplifyPubSubConfig
let pubsub

const initAmplifyPubSub = (PubSub, AWSIoTProvider, veEnvLoc, debug = false) => {
  // Apply plugin with configuration
  const iotEndPoint = getVeEnvLocIotEndpoint(vecfg, veEnvLoc, debug)
  amplifyPubSubConfig = {
    aws_pubsub_region: getVeEnvLocAwsRegion(vecfg, veEnvLoc, debug),
    aws_pubsub_endpoint: 'wss://' + iotEndPoint + '/mqtt',
    clientId: mqtt.clientId(debug)
  }
  addToLog('initAmplifyPubSub:amplifPubSubConfig: ' + JSON.stringify(amplifyPubSubConfig), debug)
  if (!pubsub) pubsub = PubSub // new PubSub({})
  pubsub.addPluggable(new AWSIoTProvider(amplifyPubSubConfig))
  return amplifyPubSubConfig
}

let reconnectInProgress = false

const reconnectAmplifyPubSub = (AWSIoTProvider, debug = false) => {
  if (reconnectInProgress) {
    addToLog('reconnectAmplifyPubSub is already in progress', true)
    return
  }
  addToLog('reconnectAmplifyPubSub:reconnecting...', true)
  reconnectInProgress = true
  pubsub.removePluggable('AWSIoTProvider') // Remove plugin using the provider name
  pubsub.addPluggable(new AWSIoTProvider(amplifyPubSubConfig))
  reconnectInProgress = false
  return amplifyPubSubConfig
}

const amplifyPubSubOnError = async (error, debug = false) => {
  if (reconnectInProgress) {
    addToLog('amplifyPubSubOnError:already reconnecting...', true)
    return
  }
  addToLog('amplifyPubSubOnError:error: ' + JSON.stringify(error), true)
  if (error.error === 'Disconnected, error code: 8') {
    reconnectInProgress = true
    addToLog('amplifyPubSubOnError:disconnect error code 8 occured', true)
    await sleep(1000)
    amplifyPubSubConfig = reconnectAmplifyPubSub(ve.AWSIoTProvider, debug)
    addToLog('amplifyPubSubOnError:amplifyPubSubConfig: ' + JSON.stringify(amplifyPubSubConfig), debug)
    await sleep(1000)
    amplifyPubSubResubscribe(debug)
    reconnectInProgress = false
  } else {
    addToLog('mqtt.amplifyPubSubOnError:error action unknown', true)
  }
}

const subs = {}

const amplifyPubSubSubscribe = (topics, debug = false) => {
  if (debug) ve.Amplify.Logger.LOG_LEVEL = 'VERBOSE'
  if (typeof topics === 'string') topics = topics.split(',')
  for (const topic of topics) { // todo: review PubSub.subscribe(topics)
    addToLog('amplifyPubSubSubscript:topic: ' + topic, debug)
    subs[topic] = ve.PubSub.subscribe(topic).subscribe({
      next: data => amplifyPubSubOnMessageArrived(topic, data, debug),
      error: error => amplifyPubSubOnError(error),
      close: () => console.log('amplifyPubSubSubscribe:close:topic: ' + topic)
    })
  }
}

const amplifyPubSubUnSubscribe = (topics, debug = false) => {
  if (typeof topics === 'string') topics = topics.split(',')
  for (const topic of topics) {
    if (hasProp(subs, topic)) {
      addToLog('amplifyPubSubUnSubscripe:topic: ' + topic, debug)
      subs[topic].unsubscribe()
      delete subs[topic]
    }
  }
}

const amplifyPubSubResubscribe = (debug = false) => {
  addToLog('amplifyPubSubResubscribe()...', debug)
  const subscribedTopics = Object.keys(subs) // []
  subscribedTopics.forEach(topic => { amplifyPubSubSubscribe(topic, debug) })
}

const amplifyPubSubOnMessageArrived = (topic, data, debug = false) => {
  addToLog('amplifyPubSubOnMessageArrived:topic: ' + topic, debug)
  addToLog('amplifyPubSubOnMessageArrived:data: ' + JSON.stringify(data), debug)
  const topicFromData = data.value[Object.getOwnPropertySymbols(data.value)[0]]
  addToLog('amplifyPubSubOnMessageArrived:topicFromData: ' + topicFromData, debug)
  const msg = data.value
  addToLog('amplifyPubSubOnMessageArrived:msg: ' + JSON.stringify(msg), debug)
  mqtt.onMessageArrived(topicFromData, msg, debug)
}

let session

const setSession = (newSession, debug = false) => {
  session = newSession
  const veEnvLoc = getVeEnvLoc(debug)
  const accessToken = session.getAccessToken().getJwtToken()
  if (accessToken) window.localStorage.setItem(veEnvLoc + 'AccessToken', accessToken)
  const refreshToken = session.getRefreshToken().getToken()
  if (refreshToken) window.localStorage.setItem(veEnvLoc + 'RefreshToken', refreshToken)
  const idToken = session.getIdToken().getJwtToken()
  if (idToken) window.localStorage.setItem(veEnvLoc + 'IdToken', idToken)
}

const getSession = (debug = false) => {
  return session || null
}

const getAmplifyAccessToken = (debug = false) => {
  addToLog('veamplify:getAmplifyAccessToken', debug)
  if (!getSession(debug)) return
  const veEnvLoc = getVeEnvLoc(debug)
  const accessToken = window.localStorage.getItem(veEnvLoc + 'AccessToken')
  addToLog('veamplify:getAmplifyAccessToken:session: ' + JSON.stringify(getSession(debug)), debug)
  if (accessToken) addToLog('getAmplifyAccessToken:accessToken.length: ' + accessToken.length, debug)
  return (accessToken === 'null') ? null : accessToken
}

const getAmplifyRefreshToken = (debug = false) => {
  const veEnvLoc = getVeEnvLoc(debug)
  const refreshToken = window.localStorage.getItem(veEnvLoc + 'RefreshToken')
  if (refreshToken) addToLog('getAmplifyRefreshToken:refreshToken.length: ' + refreshToken.length, debug)
  return (refreshToken === 'null') ? null : refreshToken
}

const getAmplifyIdToken = (debug = false) => {
  if (!getSession(debug)) return
  const veEnvLoc = getVeEnvLoc(debug)
  const idToken = window.localStorage.getItem(veEnvLoc + 'IdToken')
  if (idToken) addToLog('getAmplifyIdToken:idToken.length: ' + idToken.length, debug)
  return (idToken === 'null') ? null : idToken
}

const refreshAmplifyToken = async (debug = false) => {
  try {
    const cognitoUser = await ve.Auth.currentAuthenticatedUser()
    const currentSession = await ve.Auth.currentSession()
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      setSession(session)
      if (err) console.log('refreshAmplifyToken:currentSession.refreshToken:error: ', err)
    })
  } catch (e) {
    console.log('Unable to refresh Token', e)
  }
}

let authenticationIsInProgress = false

const isAuthenticated = (debug = false) => {
  const session = getSession(debug) || false
  if (debug) console.log('isAuthenticated:session: ', session)
  if (session) authenticationIsInProgress = false
  return session !== false
}

const isAuthenticationInProgress = (debug = true) => {
  if (debug) console.log('isAuthenticationInProgress:authenticationIsInProgress: ' + authenticationIsInProgress)
  return authenticationIsInProgress
}

const getCognitoUsername = (debug = false) => {
  const jwtToken = getAmplifyAccessToken(debug) || null
  return jwtToken ? parseJwt(jwtToken).username || '' : ''
}

const getCognitoEmail = (debug = false) => {
  const jwtToken = getAmplifyIdToken(debug) || null
  return jwtToken ? parseJwt(jwtToken).email || '' : ''
}

const getCognitoGroups = (debug = false) => {
  const jwtToken = getAmplifyAccessToken(debug) || null
  return jwtToken ? parseJwt(jwtToken)['cognito:groups'] || [] : []
}

const isMemberOfCognitoGroup = (cognitoGroup, debug = false) => {
  const groups = getCognitoGroups(debug)
  return groups.includes(cognitoGroup)
}

const isMemberOfSatGroup = async (satGroupId, debug = false) => {
  const isMember = await ve.veapi.isMemberOfSatGroup(satGroupId, debug)
  return isMember
}

const isMemberOfGroup = async (group, debug = false) => {
  let isMember = isMemberOfCognitoGroup(group, debug)
  if (isMember) return isMember
  isMember = await isMemberOfSatGroup(group, debug)
  return isMember
}

const isMemberOfGroups = async (groups, debug = false) => {
  const memberOf = []
  for (const group of groups) {
    const memberOfThisGroup = await isMemberOfGroup(group, debug)
    addToLog('isMemberOfGroups:group: ' + group + ', memberOfThisGroup: ' + memberOfThisGroup, debug)
    if (memberOfThisGroup) memberOf.push(group)
  }
  addToLog('isMemberOfGroups:memberOf: ' + JSON.stringify(memberOf), debug)
  return (memberOf.length > 0)
}

const getUserProviderName = (debug = false) => {
  const defaultProviderName = 'Cognito'
  try {
    const jwtToken = getAmplifyIdToken(debug) || null
    const identities = jwtToken ? parseJwt(jwtToken).identities : null
    const providerName = identities[0].providerName
    addToLog('getUserProviderName:providerName: ' + providerName, debug)
    return providerName
  } catch (err) {
    addToLog('getUserProviderName:defaultProviderName: ' + defaultProviderName, debug)
    return defaultProviderName
  }
}

const startCognitoLogin = (thisEnvLoc, debug = true) => {
  addToLog('startCognitoLogin:authenticationIsInProgress: ' + authenticationIsInProgress + ', thisEnvLoc: ' + thisEnvLoc, debug)
  if (JSON.stringify(ve.Auth.configure()) === '{}') {
    authenticationIsInProgress = true
    addToLog('startCognitoLogin:authenticationIsInProgress: ' + authenticationIsInProgress, debug)
    addToLog('startCognitoLogin:ve.amplify.doAmplifySignIn:thisEnvLoc:' + thisEnvLoc + '...', debug)
    doAmplifySignIn(ve.Amplify, ve.Auth, thisEnvLoc, debug)
  }
}

export default {
  getAmplifyConfig,
  getCurrentAmplifyConfig,
  initAmplifyConfig,
  doAmplifyFederatedSignIn,
  doAmplifySignIn,
  initAmplifyPubSub,
  reconnectAmplifyPubSub,
  amplifyPubSubOnError,
  amplifyPubSubSubscribe,
  amplifyPubSubUnSubscribe,
  amplifyPubSubResubscribe,
  amplifyPubSubOnMessageArrived,
  setSession,
  getSession,
  getAmplifyAccessToken,
  getAmplifyRefreshToken,
  getAmplifyIdToken,
  refreshAmplifyToken,
  isAuthenticated,
  isAuthenticationInProgress,
  getCognitoUsername,
  getCognitoEmail,
  getCognitoGroups,
  isMemberOfCognitoGroup,
  isMemberOfSatGroup,
  isMemberOfGroup,
  isMemberOfGroups,
  getUserProviderName,
  startCognitoLogin
}
