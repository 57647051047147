export default {
  cfg: {
    veGMCEnvLocs: ['ve-cd-dev-in-1', 've-cd-dev-au-1', 'tp-test-in-1', 'sy-prod-au-1'],
    veEnvLocs: ['vedev-in-1', 'veprod-in-1', 've-cd-dev-in-1', 've-cd-dev-au-1', 'tp-test-in-1', 'sy-prod-au-1'],
    envLocs: [
      {
        name: 've-prov-in-1',
        location: 'Mumbai',
        envLocInUrl: '',
        awsRegion: 'ap-south-1',
        iotEndpoint: 'a1mdfe1sg3j3nn-ats.iot.ap-south-1.amazonaws.com'
      },
      {
        name: 'vedev-in-1',
        location: 'Mumbai',
        envLocInUrl: 'vedev',
        awsRegion: 'ap-south-1',
        satApiBaseURI: 'https://dev.vegd.io/sat/v1',
        gqlUrl: '',
        userPoolId: 'ap-south-1_IrrfRoOz4',
        identityPoolId: 'ap-south-1:e38d8bd9-4eb8-4103-83e8-2a772d609bfb',
        cognitoAppClientId: '4jjv22kb2qk5d0dtocamfrukbf',
        cognitoAppWebDomain: 'vedev-auth.village.energy',
        cognitoAuthenticatedLogins: 'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_IrrfRoOz4',
        iotEndpoint: 'aup8cflk2il2h-ats.iot.ap-south-1.amazonaws.com'
      },
      {
        name: 'veprod-in-1',
        location: 'Mumbai',
        envLocInUrl: 'veprod',
        awsRegion: 'ap-south-1',
        satApiBaseURI: 'https://api.village.energy/sat/v1',
        gqlUrl: '',
        userPoolId: 'ap-south-1_3fOG0EN0N',
        identityPoolId: 'ap-south-1:bc9b017b-82fb-451e-8751-7c042d86af53',
        cognitoAppClientId: '56qju73497omkq5qqvvcfm2shm',
        cognitoAppWebDomain: 'veprod-auth.village.energy',
        cognitoAuthenticatedLogins: 'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_3fOG0EN0N',
        iotEndpoint: 'a3gaw1s8q0gl64-ats.iot.ap-south-1.amazonaws.com'
      },
      {
        name: 've-cd-dev-in-1',
        location: 'Mumbai',
        envLocInUrl: 'vecddev',
        awsRegion: 'ap-south-1',
        satApiBaseURI: 'https://cddevapi.village.energy/sat/v1',
        gqlUrl: 'https://vecddev.village.energy/v1/graphql',
        userPoolId: 'ap-south-1_CoiTAkBPy',
        identityPoolId: 'ap-south-1:a535ee2b-b592-4933-9893-f5676025abb5',
        cognitoAppClientId: '1vu86voqbkbljao5ukfgdriq28',
        cognitoAppWebDomain: 'vecddev-auth.village.energy',
        cognitoAuthenticatedLogins: 'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_CoiTAkBPy',
        iotEndpoint: 'a1uskuzyprytre-ats.iot.ap-south-1.amazonaws.com'
      },
      {
        name: 've-cd-dev-au-1',
        location: 'Sydney',
        envLocInUrl: 'vecddevau1',
        awsRegion: 'ap-southeast-2',
        satApiBaseURI: 'https://acapi.vecddevau1.village.energy/sat/v1',
        gqlUrl: 'https://gqlapi.vecddevau1.village.energy/v1/graphql',
        userPoolId: 'ap-southeast-2_DUOVkTHRn',
        identityPoolId: 'ap-southeast-2:dbee20ca-cbec-47c7-a2ad-9953957bac16',
        cognitoAppClientId: '14tmfskdd7ut6migf4na6o78qe',
        cognitoAppWebDomain: 'acauth.vecddevau1.village.energy',
        cognitoAuthenticatedLogins: 'cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_DUOVkTHRn',
        iotEndpoint: 'a1uskuzyprytre-ats.iot.ap-southeast-2.amazonaws.com'
      },
      {
        name: 'tp-test-in-1',
        location: 'Mumbai',
        envLocInUrl: 'tptest',
        awsRegion: 'ap-south-1',
        satApiBaseURI: 'https://tptestapi.village.energy/sat/v1',
        gqlUrl: 'https://tptest.village.energy/v1/graphql',
        userPoolId: 'ap-south-1_XQKobIeOB',
        identityPoolId: 'ap-south-1:eca6959e-3677-451f-a890-6b9023cf4f30',
        cognitoAppClientId: '7v8urbbmi8daeb4t5hf5aas2m8',
        cognitoAppWebDomain: 'tptest-auth.village.energy',
        cognitoAuthenticatedLogins: 'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_XQKobIeOB',
        iotEndpoint: 'a3uxuy33f2g8m2-ats.iot.ap-south-1.amazonaws.com'
      },
      {
        name: 'sy-prod-au-1',
        location: 'Sydney',
        envLocInUrl: 'syprodau1',
        awsRegion: 'ap-southeast-2',
        satApiBaseURI: 'https://acapi.syprodau1.village.energy/sat/v1',
        gqlUrl: 'https://gqlapi.syprodau1.village.energy/v1/graphql',
        userPoolId: 'ap-southeast-2_ZX48iDXy8',
        identityPoolId: 'ap-southeast-2:04cc6980-0e83-4bb1-b012-ad7149bef191',
        cognitoAppClientId: '6io7q9qlobome9gsm29am15fb0',
        cognitoAppWebDomain: 'acauth.syprodau1.village.energy',
        cognitoAuthenticatedLogins: 'cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_ZX48iDXy8',
        iotEndpoint: 'a3p2ursm4xmqtw-ats.iot.ap-southeast-2.amazonaws.com'
      }
    ]
  }
}
