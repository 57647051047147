/* global ve */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill'
import { createApp, configureCompat } from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from './App'
import router from './router/index.js'
import miniToastr from 'mini-toastr' // https://github.com/se-panfilov/mini-toastr
import Loading from 'vue-loading-overlay'
import moment from 'moment'
import { vue3Debounce } from 'vue-debounce'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueApexCharts from 'vue3-apexcharts'
import VueCodeMirror from 'vue-codemirror'
import VueResize from 'vue-resize'
import { ClientTable } from 'v-tables-3'
import MaskedInput from 'vue-text-mask'
import VCalendar from 'v-calendar'
import { StreamBarcodeReader } from 'vue-barcode-reader'
import VueBarcode from 'vue-barcode'
import Toasted from 'vue-toasted'
import tableMixins from './js/tableMixins'
import { stickyHeaders } from './js/customDirectives'
import APIError from './APIError.vue'
import Pagination from './views/base/Pagination.vue'
import TableCell from './views/base/TableCell.vue'
import vueJsonEditor from 'vue-json-editor'
import Multiselect from 'vue-multiselect'
import VueSpeedMeter from 'vue-speed-meter'
import { createI18n } from 'vue-i18n'
import messages from './translation'
import CountryFlag from 'vue-country-flag-next'
import { QuillEditor } from '@vueup/vue-quill'
import { createPinia } from 'pinia'

// Import stylesheet
import 'vue-loading-overlay/dist/css/index.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'v-calendar/dist/style.css'
// import cssVars from 'css-vars-ponyfill'
// import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

// Import javascript
import { addToLog, getVeEnvLoc } from './js/veb.js'

// Amplify
import Amplify, { Auth, PubSub } from 'aws-amplify'
import { AWSIoTProvider } from '@aws-amplify/pubsub'
import veAmplify from './js/veamplify.js'
import veSatUser from './js/vesatuser.js'

ve.su = veSatUser
ve.amplify = veAmplify
ve.Amplify = Amplify
ve.Auth = Auth
ve.PubSub = PubSub
ve.AWSIoTProvider = AWSIoTProvider

const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
}

miniToastr.init({ types: toastTypes })

configureCompat({ WATCH_ARRAY: false })

const veEnvLoc = getVeEnvLoc(true)
addToLog(`main:veEnvLoc: ${veEnvLoc}`, true)

moment.updateLocale('en', {
  longDateFormat: {
    LT: 'h:mm:ss A',
    L: 'MM/DD/YYYY',
    l: 'M/D/YYYY',
    LL: 'MMMM Do YYYY',
    ll: 'MMM D YYYY',
    LLL: 'MMMM Do YYYY LT',
    lll: 'MMM D YYYY LT',
    LLLL: 'dddd, MMMM Do YYYY LT',
    llll: 'ddd, MMM D YYYY LT'
  }
})

const i18n = createI18n({
  locale: 'en',
  allowComposition: true,
  globalInjection: true,
  fallbackLocale: 'en',
  messages
})

const veI18n = {
  install (Vue, options) {
    Vue.prototype.$veT = function (param) {
      if (param === '') return ''
      return this.$t(param)
    }
  }
}

const pinia = createPinia()

const app = createApp(App)
app.component(App)
app.component('APIError', APIError)
app.component('Multiselect', Multiselect)
app.component('Pagination', Pagination)
app.component('TableCell', TableCell)
app.component('vueJsonEditor', vueJsonEditor)
app.component('VueSpeedMeter', VueSpeedMeter)
app.component('StreamBarcodeReader', StreamBarcodeReader)
app.component('CountryFlag', CountryFlag)
app.component('QuillEditor', QuillEditor)

app.mixin(tableMixins)

app.directive('debounce', vue3Debounce({ lock: true }))
app.directive('stickyHeaders', stickyHeaders)

app.use(router)
app.use(BootstrapVue)
app.use(BootstrapVueIcons)
app.use(moment)
app.use(require('vue-moment'), { moment })
app.use(Loading)
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDUpa-S_dPonAY0PUmKXC7KF2Im3L5nlgU'
  }
})
app.use(VueApexCharts)
app.use(VueCodeMirror)
app.use(VueResize)
app.use(ClientTable, Event)
app.use(MaskedInput)
app.use(VCalendar, {
  firstDayOfWeek: 2 // Monday
})
app.use(VueBarcode)
app.use(Toasted)
app.use(i18n)
app.use(veI18n)
app.use(pinia)

app.mount('#app')

console.log('router: ', router)

export { Amplify }
export { Auth }
export { i18n }

export const appInstance = app
