/* global ve */
import {
  addToLog
} from './veb.js'
import cfg from './vecfg.js'
const vecfg = cfg.cfg
addToLog(`vecfg: ${JSON.stringify(vecfg)}`, false) // todo: remove after testing

const checkUserProviderName = async (debug = false) => {
  const userProfile = await ve.veapi.getCurrentUserUserProfile(debug) // get user profile
  addToLog(`checkUserProviderName:userProfile: ${JSON.stringify(userProfile)}`, debug)
  const providerName = ve.amplify.getUserProviderName(debug)
  addToLog(`checkUserProviderName:providerName: ${providerName}`, debug)
  if (userProfile.providerName !== providerName) {
    const response = await ve.veapi.updateCurrentUserProviderName(providerName, debug)
    addToLog(`checkUserProviderName:response: ${JSON.stringify(response)}`, debug)
    return response
  } else {
    addToLog('checkUserProviderName:providerName already set in user profile', debug)
  }
}

const getDenyAccessTo = async (debug = false) => {
  const defaultDenyAccessTo = ['Orchestration', 'Microgrid', 'Customers', 'CustomerService', 'Admin', 'Alerts', 'Events', 'Analytics', 'Financial Accounts', 'Assets', 'Contracts', 'Install']
  const denyAccessTo = []
  const hasAccessTo = await ve.veapi.getCurrentUserGroupsForType('satUiGroups', debug)
  addToLog(`getDenyAccessTo:hasAccessTo: ${JSON.stringify(hasAccessTo)}`, debug)
  for (const satUiGroup of defaultDenyAccessTo) {
    if (!hasAccessTo.includes(satUiGroup)) denyAccessTo.push(satUiGroup)
  }
  window.sessionStorage.setItem('denyAccessTo', denyAccessTo.join(','))
  const dat = window.sessionStorage.getItem('denyAccessTo')
  addToLog(`getDenyAccessTo:dat: ${dat}`, debug)
}

export default {
  checkUserProviderName,
  getDenyAccessTo
}
