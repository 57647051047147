<template>
  <b-alert v-if="apiError" :show="Boolean(this.apiError)" variant="danger">
    {{ apiError.message || apiError.code || apiError }}
    <ul class="mb-0" v-if="apiError.data && apiError.data.errors">
      <li v-for="error in apiError.data.errors" :key="error">
        {{ error }}
      </li>
    </ul>
    <div class="mt-1" v-if="apiError.data && !apiError.data.errors">{{ apiError.data }}</div>
  </b-alert>
</template>

<script>
export default {
  name: 'APIError',
  props: {
    apiError: Error
  }
}
</script>
