/* global ve */
import { createWebHashHistory, createRouter } from 'vue-router'
import { appInstance, i18n } from '../main'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainerVE')

// Views
const dashboard = () => import('@/views/dashboard')
const orchestration = () => import('@/views/orchestration')
const microgrid = () => import('@/views/microgrid')
const setupwizard = () => import('@/views/setupwizard')
const customers = () => import('@/views/customers')
const customerservice = () => import('@/views/customerservice')
const admin = () => import('@/views/admin')
const assets = () => import('@/views/assets')
const events = () => import('@/views/events')
const analytics = () => import('@/views/analytics')
const financials = () => import('@/views/financials')
const contracts = () => import('@/views/contracts')
const install = () => import('@/views/install')
const login = () => import('@/views/login')
const logout = () => import('@/views/logout')
const userProfile = () => import('@/views/UserProfile')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')

const router = createRouter({
  // mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: DefaultContainer,
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          meta: { requiresAuth: true },
          component: dashboard
        },
        {
          path: '/orchestration',
          name: 'Orchestration',
          meta: { requiresAuth: true },
          component: orchestration
        },
        {
          path: '/microgrid',
          name: 'Microgrid',
          meta: { requiresAuth: true },
          component: microgrid
        },
        {
          path: '/setupwizard',
          name: 'Setup Wizard',
          meta: { requiresAuth: true },
          component: setupwizard
        },
        {
          path: '/customers',
          name: 'Customers',
          meta: { requiresAuth: true },
          component: customers
        },
        {
          path: '/customerservice',
          name: 'CustomerService',
          meta: { requiresAuth: true },
          component: customerservice
        },
        {
          path: '/admin',
          name: 'Admin',
          meta: { requiresAuth: true },
          component: admin
        },
        {
          path: '/assets',
          name: 'Assets',
          meta: { requiresAuth: true },
          component: assets
        },
        {
          path: '/events',
          name: 'Events',
          meta: { requiresAuth: true },
          component: events
        },
        {
          path: '/analytics',
          name: 'Analytics',
          meta: { requiresAuth: true },
          component: analytics
        },
        {
          path: '/financials',
          name: 'Financial Accounts',
          meta: { requiresAuth: true },
          component: financials
        },
        {
          path: '/contracts',
          name: 'Contracts',
          meta: { requiresAuth: true },
          component: contracts
        },
        {
          path: '/install',
          name: 'Install',
          meta: { requiresAuth: true },
          component: install
        },
        {
          path: '/login',
          name: 'Log In/Out',
          meta: { public: true, disableIfLoggedIn: false, requiresAuth: false },
          component: login
        },
        {
          path: '/logout',
          name: 'Log Out',
          meta: { requiresAuth: true },
          component: logout
        },
        {
          path: '/user-profile',
          name: 'User Profile',
          meta: { requiresAuth: true },
          component: userProfile
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        { path: '404', name: 'Page404', component: Page404 },
        { path: '500', name: 'Page500', component: Page500 }
      ]
    }
  ]
})

// default deny access nav items
const defaultDenyAccessTo = [
  'Orchestration',
  'Microgrid',
  'Customers',
  'CustomerService',
  'Admin',
  'Alerts',
  'Events',
  'Analytics',
  'Financial Accounts',
  'Contracts',
  'Install'
]
const denyAccess = (toName) => {
  let denyAccessTo = window.sessionStorage.getItem('denyAccessTo')
  denyAccessTo = typeof denyAccessTo === 'string' ? denyAccessTo.split(',') : defaultDenyAccessTo
  return denyAccessTo.includes(toName)
}

const displayToast = (message) => {
  appInstance.config.globalProperties.$toasted.show(message, {
    type: 'error',
    theme: 'outline',
    position: 'top-right',
    duration: 2000
  })
}

router.beforeEach((to, from, next) => {
  // console.log('router.beforEach:from: ', from)
  // console.log('router.beforEach:to: ', to)
  if (to.path === '/login') {
    next()
  } else {
    const isAuthenticated = ve.amplify.isAuthenticated() || false
    const requiresAuth = to.matched.some((r) => r.meta.requiresAuth)
    // console.log('router.beforeEach:requiresAuth: ' + requiresAuth)
    if (requiresAuth && !isAuthenticated) next('login')
    else if (!requiresAuth && isAuthenticated) next('dashboard')
    else {
      if (denyAccess(to.name)) {
        displayToast(
          `${i18n.global.t(to.name)} ${i18n.global.t('Access Denied')}`
        )
        next(false)
      } else next()
    }
  }
})

export default router
