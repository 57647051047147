<template>
  <div v-if="!isJSON" class="m-0 p-0 icon-with-text">
    <b-button v-if="displayData || displayData === false" class="icon m-0 p-1" variant="light">
      <i class="fa fa-copy cursor-pointer" @click="copyToClipboard(copyData || displayData)"/>
    </b-button>
    <div class="maxHeight">{{ displayData }}</div>
  </div>
  <div v-else-if="displayData" class="icon-with-text">
    <div class="jsonBox"><pre>{{ JSON.stringify(displayData, null, 2) }}</pre></div>
    <b-button class="icon m-0 p-1" variant="light">
      <i class="fa fa-copy cursor-pointer" @click="copyToClipboard(JSON.stringify(displayData))"/>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'TableCell',
  props: ['copyData', 'displayData', 'isJSON']
}
</script>

<style>
.icon-with-text {
  position: relative;
}

.icon {
  position: absolute;
  right: 0;
  top: 0;
}

.maxHeight {
  max-height: 100px !important;
  overflow-y: auto;
}

.jsonBox {
  border: solid lightgrey 0px;
  max-height: 100px;
  max-width: 300px;
  overflow-y: auto;
}
</style>
